import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './adhaarOTP.css';

const AadhaarOtp = () => {
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [response, setResponse] = useState(null);

    const handleRequestOtp = async () => {
        try {
            Swal.fire({
                title: 'Sending OTP...',
                text: 'Please wait while we request OTP for the provided Aadhaar number.',
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading()
            });

            const headers = {
                'app-id': 'IDV-Client-Ribbon',
                'Content-Type': 'application/json'
            };

            const data = {
                aadhaarNumber,
                consent: "Y",
                consentText: "I hear by declare my consent agreement for fetching my Aadhaar information"
            };

            const result = await axios.post('https://idv.myearth.id/request-otp', data, { headers });
            setResponse(result.data);

            Swal.fire({
                icon: 'success',
                title: 'OTP Sent Successfully',
                text: 'An OTP has been sent to your registered mobile number.'
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to Send OTP',
                text: error.response.data.message || 'Something went wrong while requesting OTP.'
            });
        }
    };

    const handleVerifyOtp = async () => {
        try {
            if (!response.request_id || !response.task_id) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Data',
                    text: 'Request ID or Task ID is missing from the OTP response.'
                });
                return;
            }

            Swal.fire({
                title: 'Verifying OTP...',
                text: 'Please wait while we verify the entered OTP.',
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading()
            });

            const headers = {
                'app-id': 'IDV-Client-Ribbon',
                'Content-Type': 'application/json'
            };

            const data = {
                requestId: response.request_id,
                otp,
                consent: "Y",
                consentText: "I hear by declare my consent agreement for fetching my information from my Aadhaar",
                taskId: response.task_id
            };

            const result = await axios.post('https://idv.myearth.id/verify-otp', data, { headers });
            setResponse(result.data);

            Swal.fire({
                icon: 'success',
                title: 'Verification Successful',
                text: 'Your Aadhaar OTP has been verified successfully.'
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'OTP Verification Failed',
                text: error.response.data.message || 'Something went wrong during OTP verification.'
            });
        }
    };

    return (
        <div className="container">
            <div className="wrapper">
                <div className="left-pane">
                    <h3>Aadhaar Verification</h3>

                    <div className="input-container">
                        <div className='instext'>
                            <p>Fill in the below details to run verification</p>
                        </div>
                        <input
                            type="text"
                            placeholder="Enter Aadhaar Number"
                            value={aadhaarNumber}
                            onChange={(e) => setAadhaarNumber(e.target.value)}
                        />
                        <button onClick={handleRequestOtp}>Send OTP</button>
                    </div>

                    {response && response.request_id && (
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button onClick={handleVerifyOtp}>Run Verification</button>
                        </div>
                    )}
                </div>

                <div className='linee'></div>

                <div className="right-pane">
                    <h3>Verification Data</h3>
                    {response && (
                        <div className="response-container">
                            <pre>{JSON.stringify(response, null, 2)}</pre>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AadhaarOtp;
